<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            Invitation Details
            <v-spacer></v-spacer>
          </v-card-title>
          <div v-if="user">
            <v-row class="pt-8">
              <v-col cols="12" sm="8" md="8">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title"> Added By </span>
                      </v-col>
                      <v-col cols="7" v-if="user.addedby">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.addedby.firstname }}
                          {{ user.addedby.lastname }}</span
                        >
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Phone</span>
                      </v-col>
                      <v-col cols="7" v-if="user.addedby">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{
                          user.addedby.phone
                        }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="4" md="5">
                        <span class="item_title">Email</span>
                      </v-col>
                      <v-col cols="8" md="7" v-if="user.addedby">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{
                          user.addedby.email
                        }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="4" md="5">
                        <span class="item_title">Invitation Category</span>
                      </v-col>
                      <v-col cols="8" md="7" v-if="user.invitationCategory">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{
                          user.invitationCategory.name
                        }}</span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Create Date</span>
                      </v-col>
                      <v-col cols="7" v-if="user.addedby">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ formatDate(user.createdDate) }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.occassion">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Occassion</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.occassion }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.name">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Name</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.name }} </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.groom">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Groom Name</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.groom }} </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.bride">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Bride Name</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.bride }} </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.dateOfOccassion">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Date Of Occassion</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ formatDate(user.dateOfOccassion) }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.timeOfOccassion">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Time Of Occassion</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.timeOfOccassion }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.locality">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Locality</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.locality }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.streetAddress">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">Street Address</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.streetAddress }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.city">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">City</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2">{{ user.city }} </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                  <v-col cols="12" md="12" v-if="user.district">
                    <v-row>
                      <v-col cols="5">
                        <span class="item_title">District</span>
                      </v-col>
                      <v-col cols="7">
                        <span class="item_value">-</span>
                        <span class="item_value ma-2"
                          >{{ user.district }}
                        </span>
                      </v-col>
                    </v-row>
                    <hr />
                  </v-col>
                </v-row>
              </v-col>
              <v-flex xs12 sm6 md4 text-center>
                <v-layout wrap justify-center v-if="user.invitationCategory">
                  <v-flex xs12 sm8 text-right>
                    <v-img contain :src="baseURL + user.image">
                    </v-img>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs10 align-self-center text-center>
                    <v-card outlined elevation="0" tile>
                      <v-layout wrap py-1>
                        <v-flex>
                          <span
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xs'
                                  ? '10px'
                                  : $vuetify.breakpoint.name == 'sm'
                                  ? '13px'
                                  : $vuetify.breakpoint.name == 'md'
                                  ? '10px'
                                  : $vuetify.breakpoint.name == 'lg'
                                  ? '13px'
                                  : '13px',
                            }"
                            >{{ copyURL }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs2>
                    <v-card
                      style="cursor: pointer"
                      outlined
                      color="blue"
                      elevation="0"
                      tile
                    >
                      <v-layout wrap py-1>
                        <v-flex text-center>
                          <span
                            @click.stop.prevent="copyImageUrl"
                            style="color: #fff; text-decoration: none"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xs'
                                  ? '13px'
                                  : $vuetify.breakpoint.name == 'sm'
                                  ? '16px'
                                  : $vuetify.breakpoint.name == 'md'
                                  ? '13px'
                                  : $vuetify.breakpoint.name == 'lg'
                                  ? '16px'
                                  : '16px',
                            }"
                          >
                            copy
                          </span>
                          <input
                            type="hidden"
                            id="testing-code"
                            :value="copyURL"
                          />
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      user: [],
      frz: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      product: [],
      photos: [],
      copyURL: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(winStep) {
      if (winStep.showsnackbar) {
        this.showsnackbar = true;
        this.msg = winStep.msg;
      }
    },
    copyImageUrl() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? " Image URL copied successfully"
          : "unsuccessful";
        this.msg = msg;
        this.showsnackbar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showsnackbar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/invitation/friends/get",
        params: {
          id: this.$route.query.userId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.frz = response.data.friends;
            this.copyURL =
              "https://styloop.in/shopByLook?greetings=" + this.user.image;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
    formatDate1(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
.Content {
  text-align: center;
  position: relative;
  padding-top: 210px;
}
.ContentFestival {
  text-align: center;
  position: relative;
  padding-top: 210px;
}
</style>
